.user-chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  gap: 10px;
  padding-right: 20px;
}

.user-chat-profile {
  display: flex;
  gap: 10px;
}

.user-chat p {
  max-width: 500px;
  padding: 10px;
  border: 1px solid #e4e9ee;
  border-radius: 8px;
  background: #e4e9ee;
  color: #334d6e;
}
