.processed-theme-main-h {
  width: 100%;
  min-height: 100vh;
  padding-left: 300px;
  padding-top: 100px;
  padding-right: 40px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: #f4f5fa;
}

.processed-theme-main-h::-webkit-scrollbar {
  display: none;
}

.processed-theme-container {
  /* padding: 20px; */
  border-radius: 8px;
  min-height: 80vh;
  background: transparent;
}

.project-download-button {
  max-width: 156px;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #109cf1;
  background: white;
  outline: none;
  color: #109cf1;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.project-name {
  max-width: 300px;
  text-transform: capitalize;
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 600;
}

.loading-tab {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-tab p {
  font-weight: 900;
  font-size: 24px;
}
