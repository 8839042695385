/* App.css */
.dynamic-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border-radius: 8px;
  height: 473px;
  overflow: auto;
  padding: 20px;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.input-field {
  display: flex;
  align-items: flex-end;
  gap: 30px;
  animation: fadeIn 0.3s ease-in;
  width: 100%;
  margin-bottom: 30px;
}

.user-name-field {
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.user-name-field label {
  color: #818e9b;
  font-weight: 500;
  font-size: 11px;
}

.user-name-field input {
  border: none;
  outline: none;
  margin: 20px 0 10px 0;
  border-bottom: 1px solid #d3d8dd;
  font-size: 13px;
  padding-bottom: 10px;
}

.input-field textarea {
  padding: 8px;
  border: 1px solid #cfd3d4;
  border-radius: 4px;
  width: 100%;
  max-width: 450px;
  resize: none;
  height: 88px;
  outline: none;
  color: black;
}

.input-field button {
  color: #c2cfe0;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  background: white;
}

.submit-button {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.submit-button button {
  background: #6d4697;
  color: white;
  border: none;
  outline: none;
  border-radius: 12px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  width: 180px;
  height: 58px;
  transition: 0.3s ease;
}

.add-button {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #6d4697;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
