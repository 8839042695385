.progress-loader-main {
  position: absolute;
  margin: 0;
  padding: 0;
  right: -50px;
}

.progress-loader-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

.progress-loader-main .progress-loader-inner p {
  position: absolute;
  margin: 0;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
}
