.terms-conditions-main {
  padding: 100px 0;
  width: 100%;
  height: 100%;
  font-family: "DM Sans", sans-serif;
}

.terms-conditions-box {
  max-width: 800px;
  width: 100%;
  margin: auto;
}

.terms-conditions-box h2 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px;
}

.terms-conditions-box h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}

.terms-conditions-box p {
  font-size: 20px;
  font-weight: 400;
}

.terms-conditions-box button {
  width: 200px;
  height: 50px;
  margin-top: 50px;
  border: none;
  outline: none;
  border-radius: 12px;
  background: #3e1f79;
  color: white;
}
