.ai-profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-circle {
  width: 20px;
  height: 20px;
  background: #a2a1a1;
  border-radius: 50%;
}

.ai-chat {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.ai-chat span {
  max-width: 500px;
  background: #ecd8ff;
  color: black;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  font-size: 15px;
}

.ai-chat p{
  margin: 0;
}
