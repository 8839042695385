.collapsible-paragraph p.collapsed {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Limit to 5 lines */
  -webkit-box-orient: vertical;
  max-width: 700px;
  width: 100%;
  font-size: 16px;
}

.collapsible-paragraph p.expanded {
  overflow: visible;
  max-width: 700px;
  width: 100%;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #53545C;
}

.toggle-button:focus {
  outline: none;
}
