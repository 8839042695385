.payment-main {
  min-height: 100vh;
  width: 100%;
  padding: 80px 40px 30px 300px;
  display: flex;
  background: #f4f5fa;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-family: "DM Sans", sans-serif;
}

.payment-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
}

.payment-headings {
  text-align: left;
  margin-top: 30px;
  width: 100%;
}

.payment-headings h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}

.payment-box-container {
  display: flex;
  gap: 30px;
  width: 100%;
  max-width: 1000px;
  margin-top: 50px;
  flex-wrap: wrap;
  background: #eff1f9;
  padding: 20px;
  border-radius: 12px;
}

.payment-box-container .payment-box:nth-child(2) {
  background: #3cb239;
  color: white !important;
}

.payment-box-container .payment-box:nth-child(2) p {
  color: white;
}

.payment-box {
  height: 100%;
  width: 100%;
  background: #ffffff;
  text-align: left;
  border-radius: 10px;
  padding: 30px 20px;
  font-family: "DM Sans", sans-serif;
  flex-basis: 300px;
  flex-grow: 1;
  position: relative;
}

.payment-box .current-plan-tag {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #6d4697;
  padding: 5px;
  width: 123px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.payment-box .current-plan-tag p {
  margin: 0;
  color: white;
  font-size: 13px;
  text-align: center;
}

.payment-box h3 {
  font-size: 18px;
  color: #939eae;
  font-weight: 400;
}

.payment-box h2 {
  font-size: 42px;
  font-weight: bolder;
}

.payment-box h2 span {
  font-size: 15px;
  color: #939eae;
  font-weight: bolder;
}

.payment-box p {
  color: #939eae;
  font-weight: 400;
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
}

.payment-box button {
  background: white;
  width: 100%;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 10px;
  color: #3e1f79;
  margin-top: 20px;
  border: 1px solid #3e1f79;
}

.subscription-values {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.subscription-values span {
  font-size: 16px;
  font-weight: 400;
  color: #12141d;
}

.tick-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #12141d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-values {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #183d3d;
  width: 100%;
}

.subscription-downgrade-box {
  max-width: 284px;
  height: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px;
}

.subscription-downgrade-box p {
  font-size: 14px;
  color: #1c1d22;
}

.subscription-downgrade-box button {
  border: 1px solid #3e1f79;
  background: white;
  color: #3e1f79;
  padding: 10px;
  border-radius: 4px;
  transition: 0.3s ease;
}

.subscription-downgrade-box button:hover {
  background: #3e1f79;
  color: white;
}
