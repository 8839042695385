.user-project-name {
  max-width: 300px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.user-input-main {
  width: 100%;
  min-height: 100vh;
  padding-left: 300px;
  padding-top: 80px;
  padding-right: 40px;
  background: #f4f5fa;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.user-project-name p {
  margin: 10px;
  text-transform: capitalize;
}

.user-input-selection {
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
}

.user-input-selection button {
  position: absolute;
  right: 0;
  height: 100%;
  width: 150px;
  border-radius: 8px;
  border: 1px solid #6d4697;
  background: white;
  color: #6d4697;
}

.user-input-selection p {
  font-size: 14px;
  margin: 0;
}

.audio-video-data {
  cursor: pointer;
  user-select: none;
  transition: 0.5s ease;
  font-weight: 400;
  max-width: 331px;
  width: 100%;
  background: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #8a8d8f;
}

.text-data {
  cursor: pointer;
  user-select: none;
  transition: 0.5s ease;
  font-weight: 400;
  max-width: 331px;
  width: 100%;
  background: white;
  text-align: center;
  padding: 10px;
  color: #8a8d8f;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
