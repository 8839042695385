.reset-password-main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot-password-heading {
  width: 100%;
}

.forgot-password-heading h2 {
  color: #3e1f79;
  font-size: 32px;
  font-weight: bolder;
}

.forgot-password-heading p {
  color: #3e1f79;
  font-size: 18px;
}

.reset-password-box h1 {
  color: #334d6e;
  margin-top: 30px;
}

.reset-password-box form {
  width: 100%;
  margin-top: 20px;
}

.reset-password-box form button {
  width: 100%;
  padding: 10px;
  margin-top: 16px;
  border: none;
  outline: none;
  background: #3e1f79;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  border-radius: 4px;
  height: 57px;
}

.reset-password-box {
  max-width: 500px;
  height: fit-content;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 40px;
}

.reset-password-navigation {
  display: flex;
  gap: 5px;
  margin-top: 10px;
  font-size: 18px;
  color: #3e1f79;
}

.reset-password-navigation p:first-child {
  cursor: pointer;
  user-select: none;
}

.reset-password-navigation p:last-child {
  cursor: pointer;
  user-select: none;
}
