.main-container {
  display: flex;
}

.modal-body .modal-heading p {
  font-size: 20px;
  color: #334d6e;
  font-weight: 600;
  margin-bottom: 36px;
}

.modal-body .modal-input {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-bottom: 59px;
}

.modal-input label {
  font-size: 11px;
  color: #818e9b;
  margin-bottom: 14px;
}

.modal-input input {
  border: none;
  border-bottom: 1px solid #d3d8dd;
  padding-bottom: 5px;
}

.modal-input input:focus {
  outline: none;
  border-bottom: 1px solid #d3d8dd;
}
.modal-button {
  display: flex;
  justify-content: flex-end;
}

.modal-button button {
  background: #6D4697;
  padding: 5px 30px;
  border: none;
  color: white;
  font-weight: 500;
  border-radius: 4px;
}
