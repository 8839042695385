.ap {
  width: 8em;
  height: 16em;
}
.ap__ring {
  stroke: hsla(var(--hue), 10%, 10%, 0.15);
  transition: stroke 0.3s;
}
.ap__worm1,
.ap__worm2 {
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.ap__worm1 {
  animation-name: worm1;
}
.ap__worm2 {
  animation-name: worm2;
  visibility: hidden;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 10%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
  .ap__ring {
    stroke: hsla(var(--hue), 10%, 90%, 0.1);
  }
}

/* Animtions */
@keyframes worm1 {
  from {
    animation-timing-function: ease-in-out;
    stroke-dashoffset: -87.96;
  }
  20% {
    animation-timing-function: ease-in;
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dashoffset: -791.68;
    visibility: visible;
  }
  60.1%,
  to {
    stroke-dashoffset: -791.68;
    visibility: hidden;
  }
}
@keyframes worm2 {
  from,
  60% {
    stroke-dashoffset: -87.96;
    visibility: hidden;
  }
  60.1% {
    animation-timing-function: cubic-bezier(0, 0, 0.5, 0.75);
    stroke-dashoffset: -87.96;
    visibility: visible;
  }
  77% {
    animation-timing-function: cubic-bezier(0.5, 0.25, 0.5, 0.88);
    stroke-dashoffset: -340;
    visibility: visible;
  }
  to {
    stroke-dashoffset: -669.92;
    visibility: visible;
  }
}
