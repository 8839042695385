/* App.css */
.file-drop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  height: 473px;
  justify-content: center;
  position: relative;
  background: white;
  overflow: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.file-drop-container p {
  color: #847b7b;
  font-size: 14px;
  margin-bottom: 36px;
}

.file-drop-container span {
  text-align: center;
  margin-top: 36px;
  color: #847b7b;
  font-size: 12px;
}

.drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.file-input-label {
  display: inline-block;
  margin-top: 10px;
  color: #3498db;
  cursor: pointer;
}

.file-list {
  position: absolute;
  top: 30px;
  left: 30px;
}

.file-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.file-list li {
  margin-bottom: 5px;
}

.list-items-h {
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

.list-items-h button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.list-items-h li {
  margin: 0 !important;
}

.bottom-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
}

.upload-more-files label {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.upload-more-files p {
  margin: 0;
  color: #109cf1;
}

.continue-button button {
  background: #109cf1;
  border: none;
  outline: none;
  color: white;
  width: 160px;
  height: 42px;
  border-radius: 8px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.file-names-av {
  display: flex;
  gap: 20px;
  overflow-y: auto;
}

/* Hide the scrollbar */
.file-names-av::-webkit-scrollbar {
  width: 0px; /* Set a width for the pseudo scrollbar */
}

.file-names-av::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

.file-names-av::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the thumb (actual scrollbar) */
}

.file-tab {
  width: 100px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #818e9b;
  padding-bottom: 10px;
  font-size: 13px;
  min-width: 100px;
}

.active {
  border-bottom: 1px solid #109cf1;
}

.modal-body-av {
  position: relative;
  height: 100%;
  width: 100%;
}

.modal-body-av h2 {
  color: #334d6e;
  font-size: 20px;
  margin-bottom: 30px;
}

.file-names-av {
  position: relative;
  height: 90%;
  width: 100%;
}

.trancription-loading-modal {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  position: relative;
}

.trancription-loading-modal span {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  max-width: 371px;
  width: 100%;
}

.center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, blue, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
