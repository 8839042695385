@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: #dde6ed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cloudinary-button {
  background-color: #6d4697;
  border: none;
  outline: none;
  cursor: pointer;
  max-width: 180px;
  width: 100%;
  height: 58px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  gap: 20px;
  font-size: 20px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
