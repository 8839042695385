.signup-container {
  width: 100%;
  height: 100vh;
  background: white;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.signup-main-container {
  max-width: 450px;
  width: 100%;
}

.signup-main-container h2 {
  font-size: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #3e1f79;
}

.signup-main-container p {
  font-size: 21px;
  font-family: "Poppins", sans-serif;
  font-weight: lighter;
  color: #3e1f79;
  margin-bottom: 40px;
}

.terms-and-services-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.terms-and-services-box p {
  margin: 0;
  font-size: 15px;
  cursor: pointer;
}

.signup-buttons {
  width: 100%;
  margin-top: 20px;
}

.signup-buttons button {
  height: 57px;
  width: 100%;
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #3e1f79;
}

.signup-buttons button:first-child {
  background: #3e1f79;
  color: white;
  font-size: 20px;
}

.signup-buttons button:last-child {
  background: white;
  border: 1px solid #3e1f79;
  color: #3e1f79;
  font-size: 20px;
}

.signup-google-button {
  background: white;
  font-size: 20px;
  color: #3e1f79;
  border: 1px solid #3e1f79;
}
