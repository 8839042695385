.single-project-main {
  width: 100%;
  min-height: 100vh;
  padding-left: 300px;
  padding-top: 60px;
  padding-right: 20px;
  background: #f4f5fa;
}

.single-project-main::-webkit-scrollbar {
  display: none;
}

.single-project-main {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.single-project-name {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.single-project-name input {
  padding: 5px 10px;
  border: none;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  background: transparent;
}

/* .project-delete-button {
  right: 30px;
  top: 80px;
  border: none;
  outline: none;
  border-radius: 8px;
  color: red;
  background: white;
} */

.project-top-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 10px 0;
}

.project-top-buttons .project-back-button {
  border: none;
  outline: none;
  background: transparent;
}

/* .project-delete-button:hover {
  background: white;
  color: red;
} */

.modal-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-button-group button {
  max-width: 180px;
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 8px;
  color: #53545c;
  cursor: pointer;
}

.modal-button-group button:last-child {
  background: #f57e77;
  color: white;
  border: none;
}

.delete-modal-info {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 38px;
}

.delete-modal-info p {
  margin: 0;
  font-size: 20px;
}
