.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  max-width: 441px;
  width: 100%;
}

.login-heading h2 {
  font-size: 40px;
  font-weight: bolder;
  color: #3e1f79;
}

.login-heading {
  margin-bottom: 57px;
}

.login-heading p {
  font-size: 20px;
  font-weight: lighter;
  color: #3e1f79;
}

.remember-me-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forgot-password-login p {
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
}

.login-button {
  width: 100%;
  margin-top: 20px;
}

.login-box button {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background: #3e1f79;
  color: white;
  border-radius: 10px;
  font-weight: 700;
  height: 57px;
  font-size: 20px;
}

.or-line {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 20px 0;
}

.or-line div {
  height: 1px;
  background: gray;
  width: 100%;
}

.or-line span {
  margin: 0 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.google-login-button button {
  background: white;
  color: #3e1f79;
  border: 1px solid #3e1f79;
  font-size: 20px;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.navigate-to-signup {
  margin-top: 20px;
  text-align: center;
}

.navigate-to-signup p span {
  color: #3e1f79;
  cursor: pointer;
  user-select: none;
}

/* .signup-link {
  color: blue;
  cursor: pointer;
}





.google-button {
  background: white;
  border: none;
  outline: none;
  border-radius: 8px;
  border: 1px solid gray;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.forget-password-button {
  background: white;
  border: none;
  outline: none;
  color: blue;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
} */
