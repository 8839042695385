.patterns-trends-main {
  padding: 20px 20px 20px 20px;
  background: #eff1f9;
  margin-top: 20px;
  border-radius: 12px;
}

.patterns-trends-box {
  border-radius: 8px;
  width: 100%;
  min-height: 270px;
  padding: 10px;
  position: relative;
  background: white;
  margin-bottom: 12px;
}

.patterns-trends-box h2 {
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.data-h {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.patterns-trends-box ul li {
  color: #334d6e;
  font-size: 16px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
}
/* 
.patterns-trends-box ul {
  padding: 0;
}



.patterns-trends-box ul li p {
  max-width: 260px;
} */

/* .patterns-data {
  display: flex;
  justify-content: space-between;
  font-weight: 200;
  font-size: 15px;
  color: #4f4f4f;
} */

.patterns-trends-box button {
  border: none;
  outline: none;
  background: white;
  color: #53545c;
  cursor: pointer;
}

.patterns-1 {
  display: flex;
  gap: 12px;
}

.patterns-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.patterns-header p {
  margin: 0;
}
