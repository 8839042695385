.sidebar-container {
  width: 256px;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  padding: 24px;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 100px;
}

.project-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.project-actions button {
  border: none;
  outline: none;
  text-align: left;
  background: white;
  display: flex;
  gap: 14px;
  align-items: center;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #53545c;
  padding: 16px;
  border-radius: 12px;
}

.user-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-data .user-name {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  color: #192a3e;
}

.user-data .user-email {
  font-size: 11px;
  color: #90a0b7;
}

.logout-button {
  background: white;
  border: none;
  outline: none;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  width: 100%;
  font-size: 14px;
  color: #cc5f5f;
}

.sidebar-plan-type {
  display: flex;
  flex-direction: column;
  gap: 26px;
  width: 100%;
  padding: 9px 14px;
  border-radius: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff5e9;
}

.user-image img {
  width: 42px;
  height: 42px;
  border-radius: 8px;
}

.upper-plan-type {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upper-plan-type p {
  font-size: 14px;
  margin: 0;
  color: #1c1d22;
}

.upper-plan-type span {
  font-size: 12px;
  margin: 0;
  color: #6e7079;
  display: flex;
  align-items: center;
  gap: 5px;
}

.upper-plan-type img {
  width: 24px;
}

.user-data button img {
  width: 30px;
}

.lower-plan-type {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.lower-plan-type p {
  margin: 0;
  font-size: 14px;
  color: #53545c;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.contact-support-box {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 15px;
  background: #eff0f0;
  border-radius: 16px;
  margin-bottom: 18px;
  cursor: pointer;
}

.contact-support-box p {
  margin: 0;
}
