.files-main {
  padding: 15px;
  padding-top: 30px;
  background: #EFF1F9;
  border-radius: 12px;
}

.files-main h3 {
  color: #192a3e;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.files-textual-data {
  padding-top: 14px;
}

.files-textual-box {
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
  background: white;
  padding: 20px;
  border-radius: 12px;
}

.files-textual-box span {
  font-size: 16px;
  font-weight: bold;
}
