.analysis-main {
  width: 100%;
  height: 100%;
  background: #eff1f9;
  padding: 20px;
  border-radius: 12px;
}

.analysis-box span {
  font-weight: 200;
  margin-bottom: 50px;
  color: #334d6e;
  font-size: 16px;
  line-height: 165%;
  letter-spacing: 1%;
}

.analysis-data {
  margin-bottom: 20px;
  background: white;
  border-radius: 12px;
  padding: 20px;
}

.analysis-data h2 {
  font-size: 20px;
  font-weight: 400;
}

.analysis-main .analysis-data td{
  color: #334d6e;
  font-weight: 400;
  font-size: 16px;

}
