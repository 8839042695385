.sub-tab-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sub-tab-body-main {
  display: flex;
  width: 100%;
  gap: 12px;
}

.sub-tab-box {
  max-width: 296px;
  height: 39px;
  width: 100%;
  background: white;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
}

.sub-tab-box button {
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
}

.sub-tab-body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sub-tab-body p {
  margin: 0;
}

.insightio-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
}

.loader-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.loader-body p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
