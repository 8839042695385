.contact-main {
  background: #f4f5fa;
  min-height: 100vh;
  width: 100%;
  padding-left: 300px;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-right: 30px;
}

.contact-main h2 {
  margin-top: 50px;
  font-size: 32px;
  font-weight: 500;
}

.contact-main p {
  max-width: 420px;
  width: 100%;
  font-size: 14px;
  color: #334d6e;
}

.contact-boxes-main {
  display: flex;
  margin-top: 25px;
  gap: 16px;
}

.contact-boxes-main .contact-form-box {
  max-width: 475px;
  width: 100%;
  background: white;
  height: 100%;
  min-height: 100%;
  padding: 55px 32px;
  border-radius: 12px;
}

.contact-boxes-main .contact-form-input {
  width: 100%;
  margin-bottom: 20px;
}

.contact-boxes-main .contact-form-input p {
  font-size: 16px;
  color: #5e6366;
  margin-bottom: 8px;
}

.contact-boxes-main .contact-form-input input,
textarea {
  width: 100%;
  outline: none;
  padding: 12px 16px;
  border: 1px solid #cfd3d4;
  border-radius: 8px;
  color: #abafb1;
  resize: none;
}

.contact-form-box form button {
  width: 160px;
  height: 42px;
  border: none;
  outline: none;
  background: #3e1f79;
  color: white;
  border-radius: 4px;
}

.contact-main .contact-boxes-main .contact-whatsapp-box {
  background: white;
  border-radius: 12px;
  height: 100%;
  padding: 25px;
  max-width: 413px;
}

.contact-main .contact-boxes-main .contact-whatsapp-box p {
  font-size: 14px;
}

.contact-main .contact-boxes-main .contact-whatsapp-box button {
  max-width: 160px;
  width: 100%;
  height: 42px;
  border: none;
  outline: none;
  border-radius: 4px;
  background: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  font-size: 13px;
}

@media only screen and (max-width: 900px) {
  .contact-boxes-main {
    flex-direction: column;
  }
}
