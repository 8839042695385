@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-container {
  background: white;
  /* background: #edf2f7; */
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #ebeff2;
  align-items: center;
  height: 60px;
  padding-left: 24px;
}

.nav-container h2 {
  color: black;
  font-size: 18px;
  color: #109cf1;
}

.logo-container {
  cursor: pointer;
}

.logo-container img {
  width: 140px;
}
