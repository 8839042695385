.my-projects-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-left: 300px;
  padding-top: 60px;
  width: 100%;
  background: #f4f5fa;
}

.create-new-project-container {
  width: 100%;
  border-bottom: 2px solid #e1e1e1;
  padding: 30px 0;
}

.create-new-project-button {
  width: 238px;
  height: 47px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  gap: 10px;
  cursor: pointer;
  background: white;
}

.create-new-project-button p {
  font-size: 14px;
  color: #6d4697;
  margin: 0;
  width: 73%;
}

.my-projects-container {
  display: flex;
  flex-direction: column;
}

.my-projects-container .heading {
  padding: 20px 0 30px 0;
}

.my-projects-container .heading h3 {
  font-size: 20px;
  color: #334d6e;
}

.project-boxes-h {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
}

.project-box-h {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
}

.project-main-h {
  height: 162px;
  width: 150px;
  border-radius: 8px;
  position: relative;
  background: white;
  padding: 10px 22px;
}

.project-main-h svg {
  z-index: -100;
}

.cross-button {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.project-main-h p {
  font-size: 14px;
  color: #53545c;
  text-transform: capitalize;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.project-name-h {
  margin-top: 11px;
}

.project-name-h p {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
}

.user-project-interviews-count {
  color: #53545c;
  font-size: 12px;
  height: 20px;
  margin-top: 5px;
}

.user-project-interviews-count span {
  margin-left: 5px;
  font-weight: lighter;
}

.no-projects-container {
  background: white;
  width: 100%;
  max-width: 95%;
  height: 526px;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-projects-container h2 {
  font-size: 20px;
  margin-bottom: 12px;
}

.no-projects-container p {
  color: #8b8d97;
  font-size: 14px;
}

.no-projects-container .create-new-project-button p {
  color: #6d4697;
}
