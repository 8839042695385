.user-profiles-container {
  width: 100%;
  height: 100%;
  /* background: red; */
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
}

.user-profile-box {
  border: 1px solid #ebeff2;
  border-radius: 8px;
}

.user-profile-box li {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.user-profile-box h2 {
  font-size: 16px;
  color: #4f4f4f;
}

.user-profile-box p {
  font-weight: 100;
}

.user-profile-generic {
  display: flex;
  width: 100%;
  gap: 12px;
}

.user-profile-box .box {
  background: #eff1f9;
  border-radius: 12px;
  padding: 20px;
  margin-top: 12px;
  width: 100%;
}

.user-profile-box .box h2 {
  font-size: 20px;
  color: black;
  margin: 0;
  margin-bottom: 10px;
}

.user-profile-box .box p {
  color: #334d6e;
  font-size: 16px;
}

.user-profile-box .box span {
  color: #53545c;
  font-size: 9px;
}

.user-profile-profile {
  max-width: 70%;
  width: 100%;
}

.user-profile-sentiment {
  max-width: 30%;
  width: 100%;
}

.user-profile-generic {
  display: flex;
  gap: 12px;
}

.equal-box {
  width: 50%;
  margin-top: 12px;
}

.user-profiles-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.user-profiles-header button {
  background: transparent;
  border: none;
  outline: none;
  color: #53545c;
  font-size: 13px;
}

.data-list {
  padding-left: 20px;
  margin: 0;
  color: #334d6e;
}

.data-list-item {
  width: 100%;
  border-bottom: 1px solid #dee4fd;
  margin-bottom: 10px;
}

.data-list li {
  padding-bottom: 10px;
}

.sentiment-bar {
  background-image: linear-gradient(
    to right,
    #ff1501,
    #ff5f00,
    #ff8a00,
    #faaf00,
    #f2d100,
    #e9e000,
    #deef0e,
    #d0fe24,
    #c7fe32,
    #bfff3e,
    #b6ff48,
    #aeff52
  );
  height: 12px;
  border-radius: 8px;
  margin-bottom: 30px;
  position: relative;
  margin-top: 50px;
}

.sentiment-arrow {
  position: absolute;
  /* width: 100%; */
  bottom: -20px;
  transition: 0.3s ease;
}

.sentiment-result {
  display: flex;
  justify-content: space-between;
}

.sentiment-result p {
  visibility: hidden;
  margin: 0;
}
