.tab-component-main {
  width: 100%;
  text-align: left;
  padding: 10px;
  /* border: 1px solid black; */
}

.upper-tabs {
  display: flex;
  gap: 20px;
}

.upper-tab {
  width: 133px;
  cursor: pointer;
  position: relative;
}

.upper-tab-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  background: #6d4697;
  height: 3px;
  transition: 0.3s ease;
}

.upper-tab-body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
}

.upper-tab-body p {
  color: #6d4697;
}

.lower-tabs {
  margin-top: 15px;
}
