.list-items-h {
  position: relative;
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.list-items-h button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.list-items-h li {
  margin: 0 !important;
}
