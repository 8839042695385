.processed-chat-container {
  width: 100%;
  height: 75vh;
  margin-top: 10px;
  padding: 10px 10px 70px 10px;
  background: white;
  border-radius: 12px;
}

.select-user-profile {
  margin-top: 10px;
  border-bottom: 1px solid #ebeff2;
  padding-bottom: 10px;
}

.select-user-profile select {
  border: none;
  background: transparent;
  outline: none;
}

.select-user-profile select:focus {
  border: none;
  outline: none;
}

.select-user-profile select option {
  border: none;
}

.user-chat-input-field {
  border: 1px solid #e1e2e9;
  width: 100%;
  padding: 10px 10px;
  border-radius: 8px;
  margin-top: 5px;
}

.user-chat-input-field form {
  display: flex;
  justify-content: space-between;
}

.user-chat-input-field input {
  width: 100%;
  border: none;
  outline: none;
}
.user-chat-input-field button {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6D4697;
  color: black;
  border-radius: 6px;
  align-items: center;
  gap: 5px;
  width: 52px;
  height: 36px;
}

.main-chat-area {
  padding-top: 15px;
  overflow: auto;
  height: 100%;
  scroll-behavior: smooth;
}
